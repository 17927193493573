<ng-container *ngIf="portalConfig$ | async as portalConfig">
  <div class="flex flex-column h-full min-h-full max-h-full">
    <!-- nav -->
    <div
      class="px-3 sm:px-7 flex flex-wrap align-items-stretch justify-content-between relative lg:border-bottom-1 surface-border"
      style="height: 80px" [ngStyle]="{
        'background-color': portalConfig.headerColor,
        height: portalConfig.headerHeight
      }">
      <div class="flex flex-column align-items-center justify-content-center py-3">
        <img [src]="portalConfig.logoSrc" alt="Image" [height]="portalConfig.logoHeight" />
      </div>
    </div>
    <div class="flex-1 overflow-y-auto">
      <router-outlet></router-outlet>
    </div>
    <div class="surface-100 px-4 py-4 md:px-6 lg:px-8 flex flex-column justify-content-between align-items-center">
      <span class="text-600">© 2024, Captive Portal. Powered by Stem Connect.</span>
    </div>
  </div>
</ng-container>