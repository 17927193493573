import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-guest-acknowledgement',
  templateUrl: `./guest-acknowledgement.component.html`,
  styleUrls: ['./guest-acknowledgement.component.scss'],
})
export class GuestAcknowledgementComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private fb: FormBuilder,
  ) {}
  zaPhoneNumberRegex: RegExp = /^(?:\+27|0)(?:\d\s?){8}\d$/;
  portalLoginForm = this.fb.group({
    mobile_phone: [
      '',
      [Validators.required, Validators.pattern(this.zaPhoneNumberRegex)],
    ],
  });

  apIp: string = '';

  get f() {
    return this.portalLoginForm.controls;
  }

  ngOnInit(): void {}

  onAcknowledgeSubmit(): void {
    const url = 'https://finbondmutualbank.co.za';
    console.log('Form valid: ', this.portalLoginForm.valid);
    console.log('Form values: ', this.portalLoginForm.value);
    console.log('Original form values: ', this.portalLoginForm);

    if (this.portalLoginForm.invalid) {
      return;
    }

    if (this.portalLoginForm.valid) {
      window.open('https://finbondmutualbank.co.za', '_self');
    }
  }

  acknowledgeAccess(): void {
    // if (!this.apIp) {
    //   console.error('AP IP is missing!');
    //   return;
    // }

    this.apIp = '172.18.0.169';

    const url = `https://${this.apIp}:8080/guest/authenticate`; // AP's endpoint
    const body = 'Aruba.InstantOn.Acknowledge';

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
      },
      body: body,
    })
      .then((response) => {
        if (response.ok) {
          console.log('Acknowledgment sent successfully');
        } else {
          console.error('Failed to send acknowledgment:', response.statusText);
        }
      })
      .catch((error) => console.error('Error sending acknowledgment:', error));
  }

  closeLoginDialog() {
    this.ref.close();
  }
}
