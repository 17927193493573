import { NgModule } from '@angular/core';
import { PortalPurchaseComponent } from './components/portal-purchase/portal-purchase.component';
import { PortalRoutingModule } from './portal-routing.module';

import { PortalLoginComponent } from './components/portal-login/portal-login.component';
import { PortalFormComponent } from './components/portal-registration/portal-form.component';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PortalRedirectComponent } from './pages/portal-redirect/portal-redirect.component';
import { PortalProfileComponent } from './components/portal-profile/portal-profile.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { PortalRegisterUkComponent } from './components/portal-register-uk/portal-register-uk.component';
import { PortalAuthUkComponent } from './components/portal-auth-uk/portal-auth-uk.component';
import { SignupComponent } from './pages/signup/signup.component';
import { StatusDialogComponent } from './components/status-dialog/status-dialog.component';
import { SuccessDialogComponent } from './pages/support/success-dialog/success-dialog.component';
import { SupportComponent } from './pages/support/support.component';
import { PrimeModule } from 'src/app/prime.module';
import { ShareModule } from '../../_share/share.module';
import { PortalComponent } from './pages/portal/portal.component';
import { CaptiveComponent } from './pages/captive/captive.component';
import { PortalNavComponent } from './pages/portal-nav/portal-nav.component';
import { RegisterComponent } from './components/register/register.component';
import { SuccessfulLoginDialogComponent } from './components/successful-login-dialog/successful-login-dialog.component';
import { SubscriptionsUpdateDialogComponent } from '../main-application/pages/portal-management/subscriptions/subscriptions-update-dialog/subscriptions-update-dialog.component';
import { PortalAuthZaComponent } from './components/portal-auth-za/portal-auth-za.component';
import { PortalLoginUkComponent } from './components/portal-login-uk/portal-login-uk.component';
import { PortalPasswordResetComponent } from './components/portal-password-reset/portal-password-reset.component';
import { GuestAcknowledgementComponent } from './components/guest-acknowledgement/guest-acknowledgement.component';

@NgModule({
  declarations: [
    PortalComponent,
    PortalPurchaseComponent,
    PortalLoginComponent,
    PortalFormComponent,
    PortalRedirectComponent,
    PortalProfileComponent,
    PortalRegisterUkComponent,
    PortalAuthUkComponent,
    PortalAuthZaComponent,
    SignupComponent,
    StatusDialogComponent,
    SuccessDialogComponent,
    SupportComponent,
    CaptiveComponent,
    PortalNavComponent,
    RegisterComponent,
    SuccessfulLoginDialogComponent,
    PortalLoginUkComponent,
    PortalPasswordResetComponent,
    GuestAcknowledgementComponent,
  ],
  exports: [],
  providers: [],
  imports: [
    // angular
    CommonModule,
    FormsModule,
    // app
    PortalRoutingModule,
    // primeng
    PrimeModule,
    NgxStripeModule.forRoot(environment.stripe.publicKey),
    ShareModule,
  ],
})
export class PortalModule {}
