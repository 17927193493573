<div class="flex-1">
  <h1 class="text-3xl font-semibold text-blue-400">
    Get complimentary Wi-Fi access
  </h1>

  <p class="text-lg text-gray-600">
    Please provide your mobile phone number to get complimentary Wi-Fi access at
    <span class="font-bold">Finbond &lt;branch name&gt;</span>
  </p>
</div>

<div class="">
  <form [formGroup]="portalLoginForm" (ngSubmit)="onAcknowledgeSubmit()">
    <div class="formgrid grid p-fluid">
      <!-- Generate form fields dynamically based on a configuration object or using *ngFor if applicable -->
      <div class="field mb-4 col-12 md:col-12">
        <label for="mobile_phone" class="font-medium text-900"
          >Mobile Phone</label
        >
        <input
          id="mobile_phone"
          formControlName="mobile_phone"
          (blur)="f['mobile_phone'].markAsDirty()"
          type="text"
          pInputText
          [pValidateOnly]="true"
          [pKeyFilter]="zaPhoneNumberRegex"
          [pattern]="zaPhoneNumberRegex"
        />
        <small
          *ngIf="
            f['mobile_phone'].hasError('required') &&
            f['mobile_phone'].touched &&
            f['mobile_phone'].invalid
          "
          id="email-help"
          class="p-error"
          >This field is required</small
        >
        <small
          *ngIf="
            f['mobile_phone'].hasError('pattern') &&
            f['mobile_phone'].touched &&
            f['mobile_phone'].invalid
          "
          id="pattern-help"
          class="p-error"
          >Please enter a valid phone number</small
        >
      </div>
      <div class="field col-12 lg:mt-4">
        <button
          pButton
          pRipple
          label="Get Access"
          class="w-full p-button-success p-button-lg bg-blue-600 p-button-raised white-space-nowrap hover:bg-blue-200 mb-3 px-5 py-3 mx-1 my-1 lg:my-0"
          icon="pi pi-user"
          type="submit"
        ></button>
      </div>
    </div>
  </form>
</div>
