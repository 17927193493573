import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-portal-password-reset',
  templateUrl: './portal-password-reset.component.html',
  styleUrls: ['./portal-password-reset.component.scss'],
})
export class PortalPasswordResetComponent implements OnInit {
  constructor(
    public fb: FormBuilder,
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {}

  portalPasswordResetForm = this.fb.group({
    username: ['', Validators.required],
    first_name: ['', Validators.required],
    mobile_number: ['', [Validators.required]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    confirm_password: ['', [Validators.required, Validators.minLength(6)]],
  });

  loading = false;
  submitted = false;
  error = '';
  errorMessages: any;

  get f() {
    return this.portalPasswordResetForm.controls;
  }

  ngOnInit(): void {}

  closePasswordResetDialog() {
    this.ref.close();
  }

  onPasswordResetSubmit() {
    console.log('Form valid: ', this.portalPasswordResetForm.valid);
    console.log('Form values: ', this.portalPasswordResetForm.value);
  }
}
