import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CacheService } from 'src/app/_core/_services/cache.service';
import { ParksService } from '../../_services/parks.service';

@Component({
  selector: 'app-portal-nav',
  templateUrl: './portal-nav.component.html',
  styleUrls: ['./portal-nav.component.scss'],
})
export class PortalNavComponent implements OnInit {
  portalConfig$: BehaviorSubject<any> = new BehaviorSubject<any>({
    logoSrc:
      'https://benington-networks.co.uk/wp-content/uploads/BN-LOGO-3.svg',
    headerColor: '#ffffff',
    headerHeight: '80px',
    logoHeight: 32,
  });

  park: any;
  park_ip: any;
  parks: any;

  vno: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public cacheService: CacheService,
    public parksService: ParksService,
  ) {}

  ngOnInit(): void {
    this.vno = this.cacheService.getItem('vno');
    this.park = this.cacheService.getItem('ip');
    this.route.queryParams.subscribe((params: any) => {
      const vno = params['vno'];
      const mac = params['mac'];
      const park_ip = params['ip'];
      const login = params['login'];

      if (park_ip) {
        this.park_ip = park_ip;
        this.cacheService.setItem('ip', park_ip);
      } else {
        this.park_ip = this.cacheService.getItem('ip');
      }

      if (login) {
        this.cacheService.setItem('login', login);
      }

      this.parksService.getParks().subscribe((res: any) => {
        console.log('parks', res);
        this.parks = res;

        this.park = this.parks.filter((park: any) => {
          return park.external_reference.includes(this.park_ip);
        })[0];

        console.log('park', this.park);
      });
      if (vno) {
        this.vno = vno;
        this.cacheService.setItem('vno', vno);
      }
      if (this.vno == 'Benington') {
        this.portalConfig$.next({
          logoSrc:
            'https://benington-networks.co.uk/wp-content/uploads/BN-LOGO-3.svg',
          headerColor: '#4FADEA',
          headerHeight: '80px',
          logoHeight: 32,
        });
      } else if (this.vno == 'Finbond') {
        this.portalConfig$.next({
          logoSrc:
            // logo for mtd is in assets
            '/assets/portal/images/finbond/logo.png',
          // 'https://mtdnetworx.co.za/wp-content/uploads/2021/05/MTD-X-new-logo-2048x991.png',
          headerColor: '#fff',
          headerHeight: '120px',
          logoHeight: 64,
        });
      } else if (this.vno == 'MTD') {
        this.portalConfig$.next({
          logoSrc:
            // logo for mtd is in assets
            '/assets/portal/images/mtd/logo.png',
          // 'https://mtdnetworx.co.za/wp-content/uploads/2021/05/MTD-X-new-logo-2048x991.png',
          headerColor: '#000',
          headerHeight: '160px',
          logoHeight: 64,
        });
      } else {
        this.portalConfig$.next({
          logoSrc:
            'https://stemconnect.net/za/wp-content/uploads/sites/4/images/logo.png',
          headerColor: '#0e2c45',
          headerHeight: '80px',
          logoHeight: 32,
        });
      }

      console.log('mac', mac, typeof mac);

      if (mac >= '') {
        this.cacheService.setItem('mac', mac);
      }
      // else {
      //   this.cacheService.setItem('mac', '');
      // }
    });
  }
}
