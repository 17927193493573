<!-- heading section -->
<div class="flex geologica-benington">
  <div class="flex-1">
    <h1 class="text-3xl font-semibold text-blue-400">Reset Password</h1>
    <p class="text-lg text-gray-600">
      Please fill in the same combination of details you registered with to
      reset your password
    </p>
  </div>
  <div class="flex">
    <button
      pButton
      icon="pi pi-times"
      class="p-button-rounded p-button-danger mt-2 p-button-text"
      (click)="closePasswordResetDialog()"
    ></button>
  </div>
</div>

<!-- form section -->
<div class="geologica-benington">
  <form
    [formGroup]="portalPasswordResetForm"
    (ngSubmit)="onPasswordResetSubmit()"
  >
    <div class="formgrid grid p-fluid">
      <!-- username/email field -->
      <div class="field col-12">
        <label for="username" class="block text-900 font-medium mb-2"
          >Email</label
        >
        <input
          type="text"
          formControlName="username"
          class="form-control"
          pInputText
          class="w-full"
          (blur)="f['username'].markAsDirty()"
        />
        <small
          *ngIf="
            f['username'].hasError('required') &&
            f['username'].touched &&
            f['username'].invalid
          "
          id="email-help"
          class="p-error"
          >This field is required</small
        >
        <small
          *ngIf="
            f['username'].hasError('email') &&
            f['username'].touched &&
            f['username'].invalid
          "
          id="email-help"
          class="p-error"
          >Please enter a valid email address</small
        >
        <!-- <small *ngIf="submitted && f.username.errors && false" class="p-error"
            >Incorrect Username</small
          > -->
      </div>

      <!-- first name -->
      <div class="field col-12 md:col-6">
        <label for="first_name" class="block text-900 font-medium mb-2"
          >First Name</label
        >
        <input
          type="text"
          formControlName="first_name"
          class="form-control"
          pInputText
          class="w-full"
          (blur)="f['first_name'].markAsDirty()"
        />
        <small
          *ngIf="
            f['first_name'].hasError('required') &&
            f['first_name'].touched &&
            f['first_name'].invalid
          "
          id="first_name-help"
          class="p-error"
          >This field is required</small
        >
        <small
          *ngIf="
            f['first_name'].hasError('minLength') &&
            f['first_name'].touched &&
            f['first_name'].invalid
          "
          id="first_name-help"
          class="p-error"
          >The value entered is too short</small
        >

        <div class="field col-12" *ngIf="errorMessages">
          <!-- <div class="flex align-items-center justify-content-between"> -->
          <p-message severity="error" [text]="errorMessages"></p-message>
          <!-- </div> -->

          <!-- <span class="text-red-500 font-bold pt-3">{{ errorMessages }}</span> -->
          <!-- </div> -->
        </div>
      </div>

      <!-- mobile number field -->
      <div class="field col-12 md:col-6">
        <label for="first_name" class="block text-900 font-medium mb-2"
          >Mobile Number</label
        >
        <input
          type="text"
          formControlName="first_name"
          class="form-control"
          pInputText
          class="w-full"
          (blur)="f['first_name'].markAsDirty()"
        />
        <small
          *ngIf="
            f['first_name'].hasError('required') &&
            f['first_name'].touched &&
            f['first_name'].invalid
          "
          id="first_name-help"
          class="p-error"
          >This field is required</small
        >
        <small
          *ngIf="
            f['first_name'].hasError('minLength') &&
            f['first_name'].touched &&
            f['first_name'].invalid
          "
          id="first_name-help"
          class="p-error"
          >The value entered is too short</small
        >
      </div>

      <div class="field col-12" *ngIf="errorMessages">
        <!-- <div class="flex align-items-center justify-content-between"> -->
        <p-message severity="error" [text]="errorMessages"></p-message>
        <!-- </div> -->

        <!-- <span class="text-red-500 font-bold pt-3">{{ errorMessages }}</span> -->
        <!-- </div> -->
      </div>

      <!-- password reset section -->
      <div class="surface-border border-top-1 opacity-50 mb-4 col-12"></div>
      <div class="field col-12 md:col-12">
        <p class="text-lg text-gray-600">
          Please select a password you will remember. This password is used to
          login to the hotspot service after purchase.
        </p>
      </div>

      <div class="field col-12 md:col-6">
        <label for="password"
          >Password<span class="text-red-600">*</span></label
        >
        <p-password
          formControlName="password"
          [toggleMask]="true"
          (blur)="f['password'].markAsDirty()"
          id="password"
          class="w-full"
          mediumRegex="^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})."
          appendTo="body"
        >
          <!-- <ng-template pTemplate="header">
          <h5>Pick a password</h5>
        </ng-template>
        <ng-template pTemplate="footer">
          <p-divider></p-divider>
          <p class="mt-2">Suggestions</p>
          <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
            <li>At least one lowercase</li>
            <li>At least one uppercase</li>
            <li>At least one numeric</li>
            <li>Minimum 6 characters</li>
          </ul>
        </ng-template> -->
        </p-password>
        <small
          *ngIf="
            f['password'].hasError('minlength') &&
            f['password'].touched &&
            f['password'].invalid
          "
          id="password"
          class="p-error m-1"
          >The field <span class="font-bold">Password</span> must be at least 6
          characters</small
        >
        <small
          *ngIf="
            f['password'].hasError('maxlength') &&
            f['password'].touched &&
            f['password'].invalid
          "
          [id]="'password'"
          class="p-error m-1"
          >The field <span class="font-bold">Password</span> must be at most 20
          characters</small
        >
      </div>
      <div class="field col-12 md:col-6">
        <label for="confirm_password"
          >Confirm Password<span class="text-red-600">*</span></label
        >
        <input
          pInputText
          id="confirm_password"
          formControlName="confirm_password"
          type="password"
          class="w-full"
          (blur)="f['password'].markAsDirty()"
        />
      </div>
      <div class="field col-12 lg:mt-4">
        <button
          pButton
          pRipple
          [disabled]="loading"
          label="Reset Password"
          class="w-full p-button-info bg-pink-500 p-button-rounded p-button-raised white-space-nowrap hover:bg-pink-200 mb-3 px-5 py-3 mx-1 my-1 lg:my-0"
          icon="pi pi-user"
          type="submit"
          [loading]="loading"
        ></button>
      </div>
    </div>
  </form>
</div>
